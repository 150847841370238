
const AwsExports = {
    Auth: {
        identityPoolId: 'us-east-1:6a9f9dec-a98e-4f1f-b1da-6bf18826b6dc',
        userPoolId: 'us-east-1_QwkeAuNfk',
        userPoolWebClientId: '5olrcf324t2j7uvi43jtprjo0g',
        region: 'us-east-1',
    },
    API: {
        'aws_appsync_graphqlEndpoint': 'https://bpyzas5btbed7h7yplxp5p57by.appsync-api.ap-northeast-1.amazonaws.com/graphql',
        'aws_appsync_region': 'ap-northeast-1',
        'aws_appsync_authenticationType': 'AWS_IAM',
    },
};

export default AwsExports;
