import { useState, useEffect  } from 'react';
import API from '../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../Objects/Table';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../Objects/Modal';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);

	useEffect(() => {
	
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const getData = async () => {
				
		//件数の取得
		const ref = await API.Data.GetReserve();
		
		const newData = ref.items;
		
		//データセット
		setData(newData);
		setView(newData);

		//表示開始
		setLoad(false);
	};
		
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const handleDelete = async (ID) => {
		
		try {

			//更新
			const ref = await API.Data.DeleteReserve(ID);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。');
			}
			
			throw new Error('取消しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		
	};

	const handleClose = () => {
		setAlert([]);
		handleReload();
	};

	const formatButton = (cell, row) => {
		return (
			<div>
				<Button variant="dark" type="button" block onClick={()=>handleDelete(cell)}>取消</Button>
			</div>
		);
	};
	
	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'ID',
			text: '取消',
			sort: false,
			formatter: formatButton,
		},
		{
			dataField: 'EXE',
			text: '予約日時',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'TTL',
			text: '終了日時',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'EC2',
			text: 'WEBサーバー台数',
			sort: true,
			formatter: (cell) => cell.toLocaleString() + '台',
			classes: 'text-right',
		},
		{
			dataField: 'RDS',
			text: 'DBキャパシティ',
			sort: true,
			formatter: (cell) => cell.toLocaleString() + 'ACU',
			classes: 'text-right',
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				<Row>
					<Col sm={12}>
						<h4>予約一覧</h4>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch} name="form">
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
									<Button variant="danger" type="button" block onClick={()=>window.location.href='/reserve-add/'}>新規予約</Button>
								</Col>
							</Row>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }}>
					<Col sm={12}>
						<Table keyField={'ID'} data={view} columns={columns} sort={ {field: 'EXE', order: 'asc'} } />
					</Col>
				</Row>


			</Container>
		);
	}
};

export default withRouter(Default);
