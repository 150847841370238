import Amplify from 'aws-amplify';
import AwsExports from './include/AwsExports';
import Data from './API/Data';

Amplify.configure(AwsExports);


const API = {
	Data  : Data,
};


export default API;
