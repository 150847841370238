import { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import AwsExports from './@onocomm-system/include/AwsExports';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './Objects/Header';
import Footer from './Objects/Footer';
import StyleSheet from './Objects/StyleSheet';
import NotFound from './Objects/NotFound';
import LoginSignOut from './Pages/Login/SignOut';
import LoginSignIn from './Pages/Login/SignIn';
import LoginSignInChangePassword from './Pages/Login/SignInChangePassword';
import LoginForgotPassword from './Pages/Login/ForgotPassword';
import LoginForgotPasswordVerify from './Pages/Login/ForgotPasswordVerify';
import AccountMain from './Pages/Account/Main';
import AccountEmail from './Pages/Account/Email';
import AccountEmailVerify from './Pages/Account/EmailVerify';
import AccountPassword from './Pages/Account/Password';
import ReserveList from './Pages/ReserveList';
import ReserveAdd from './Pages/ReserveAdd';
import Main from './Pages/Main';
import History from './Pages/History';

Amplify.configure(AwsExports);

const App = () => {

	const [load, setLoad] = useState(true);
	const [auth, setAuth] = useState(undefined);

	useEffect(() => {
		
		//���O�C���`�F�b�N
		const checkAuth = async () => {

			//�F�؃X�e�[�^�X�擾
			const getAuthStatus = async () => {

				try{
					//���O�C�����[�U���̎擾
					const username = (await Auth.currentAuthenticatedUser()).username;

					if(username){
						return(true);
					}
					else{
						return(false);
					}
				}
				catch(err) {
					return(false);
				}
			};
			
			//�F�؃X�e�[�^�X�̎擾
			const status = await getAuthStatus();
			
			//���ʋL�^
			if(status){
				setAuth(true);
			}
			else{
				setAuth(false);
			}
		}
		
		//���O�C���`�F�b�N
		checkAuth();
		
		//�ǂݍ��݊���
		setLoad(false);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[true]);
	
	
	//�y�[�W����
	if(load){
		//�ǂݍ��ݒ�
		return(<div />);
	}
	else if(auth === true){
		return (
			<BrowserRouter>
				<div>
					<Header/>
					<StyleSheet/>
					<Switch>
						<Route exact path='/' component={Main} />
						<Route path='/history' component={History}/>
						<Route path='/reserve-list' component={ReserveList}/>
						<Route path='/reserve-add' component={ReserveAdd}/>
						<Route path='/account/email-verify' component={AccountEmailVerify}/>
						<Route path='/account/email' component={AccountEmail}/>
						<Route path='/account/password' component={AccountPassword}/>
						<Route path='/account' component={AccountMain}/>
						<Route path='/sign-out' component={LoginSignOut}/>
						<Route component={NotFound} />
					</Switch>
					<Footer/>
				</div>
			</BrowserRouter>
		);
	}
	else{
		return (
			<BrowserRouter>
				<div>
					<StyleSheet/>
					<Switch>
						<Route path='/forgot-password-verify/:email' component={LoginForgotPasswordVerify} />
						<Route path='/forgot-password-verify' component={LoginForgotPasswordVerify} />
						<Route path='/forgot-password' component={LoginForgotPassword} />
						<Route path='/signin-change-password/:email' component={LoginSignInChangePassword} />
						<Route component={LoginSignIn} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
};

export default App;

