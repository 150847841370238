import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Data = {};


Data.Get = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.get));
		let items = ref.data.get.items;
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Data.Put = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.put, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Data.Delete = async (ID) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.delete, { ID: ID }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Data.GetHistory = async (start_date, end_date) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.getHistory, { start_date: start_date, end_date: end_date }));
		let items = ref.data.getHistory.items;
		while (ref.data.getHistory.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.getHistory, { start_date: start_date, end_date: end_date, nextToken: ref.data.getHistory.nextToken }));
			items = items.concat(ref.data.getHistory.items);
		}
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Data.GetReserve = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.getReserve));
		let items = ref.data.getReserve.items;
		while (ref.data.getReserve.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.getReserve, { nextToken: ref.data.getHistory.nextToken }));
			items = items.concat(ref.data.getReserve.items);
		}
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Data.PutReserve = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.putReserve, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Data.DeleteReserve = async (ID) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteReserve, { ID: ID }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Data;


