import { useState, useEffect  } from 'react';
import API from '../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../Objects/Table';
import { withRouter } from 'react-router-dom';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [selectMount, setSelectMount] = useState((new Date()).getFullYear().toString() + ':' + ('00' + ((new Date()).getMonth()+1).toString()).slice(-2));
	const [web, setWEB] = useState(0);
	const [db, setDB] = useState(0);

	useEffect(() => {
	
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const getData = async () => {
		
		const year         = Number(selectMount.split(':')[0]);
		const month        = Number(selectMount.split(':')[1]);
		
		const dateObj      = new Date(year, month - 1, 1);
		const start_date = Math.floor(dateObj.getTime() / 1000);
		dateObj.setMonth(dateObj.getMonth() + 1);
		dateObj.setDate(1);
		const end_date = Math.floor(dateObj.getTime() / 1000) - 1;
		
		//console.log(start_date);
		//console.log(end_date);
		
		//件数の取得
		const ref = await API.Data.GetHistory(start_date, end_date);
		
		const newData = [];
		
		let total_web = 0
		let total_db = 0
		for(const item of ref.items){
			
			item.DIFF = Math.round( (item.TTL - item.ADD)  / 60 / 60);
			item.WEB = Number(item.DIFF * item.EC2);
			item.DB = Number(item.DIFF * item.RDS);
			newData.push(item);
			total_web = Number(total_web + item.WEB)
			total_db = Number(total_db + item.DB)
		}
		
		console.log(total_web);
		console.log(total_db);

		//データセット
		setData(newData);
		setView(newData);
		setWEB(total_web);
		setDB(total_db);

		//表示開始
		setLoad(false);
	};
	
	const generateMonth = () => {
		
		const newMonth = [];
		
		for(const i of [...Array(60).keys()]){
			const dateObj      = new Date();
			dateObj.setMonth(dateObj.getMonth() - i);
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			newMonth.push({
				year  : year,
				month : month,
			});
		}
		return(newMonth);
	};
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};
	
	const handleSelectMountOnChange = (event) => {
		setSelectMount(event.target.value);
		handleReload();
	};
	
	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'ID',
			text: '実行ID',
			sort: true,
		},
		{
			dataField: 'ADD',
			text: '実行日時',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'TTL',
			text: '終了日時',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'EC2',
			text: 'WEBサーバー台数',
			sort: true,
			formatter: (cell) => cell.toLocaleString() + '台',
			classes: 'text-right',
		},
		{
			dataField: 'RDS',
			text: 'DBキャパシティ',
			sort: true,
			formatter: (cell) => cell.toLocaleString() + 'ACU',
			classes: 'text-right',
		},
		{
			dataField: 'DIFF',
			text: '使用時間',
			sort: true,
			formatter: (cell) => cell.toLocaleString() + '時間',
			classes: 'text-right',
		},
		{
			dataField: 'WEB',
			text: 'WEB使用数',
			sort: true,
			classes: 'text-right',
		},
		{
			dataField: 'DB',
			text: 'DB使用数',
			sort: true,
			classes: 'text-right',
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h4>利用状況</h4>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch} name="form">
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={2}>
									<Form.Group controlId="select_month">
									        <Form.Control name="select_month" as="select" onChange={handleSelectMountOnChange} value={selectMount}>
									        {generateMonth().map(item => {
										        return(<option key={item.year + item.month} value={item.year + ':' + item.month}>{item.year}年{item.month}月</option>);
										})}
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={4} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
							<Row style={{ marginTop: 20 }}>
								<Col sm={12}>
									<table className="table table-bordered">
										<tr>
											<td>　</td>
											<td className="text-center">月間使用数<br/>（台数(or ACU)ｘ時間）</td>
											<td className="text-center">基本料金内<br/>（5日間WEB20台、64ACU分）</td>
											<td className="text-center">超過使用分<br/>（月間使用数 － 基本料金内）</td>
											<td className="text-center">超過料金<br/>（税別）</td>
											<td className="text-center">超過料金の課金条件<br/>（端数はすべて切り上げ計算となります）</td>
										</tr>
										<tr>
											<td>WEB使用数</td>
											<td className="text-right">{web.toLocaleString()}</td>
											<td className="text-right">{(20 * 24 * 5).toLocaleString()}</td>
											<td className="text-right">{(web - 20 * 24 * 5) > 0 ? (web - 20 * 24 * 5).toLocaleString() : 0}</td>
											<td className="text-right">{(web - 20 * 24 * 5) > 0 ? (Math.round((web - 20 * 24 * 5) / 720) * (120000 * 0.05)).toLocaleString() : 0}円</td>
											<td>「超過使用分」に720（月間使用時間）を割ったものに基本料金（12万円税別）の5％({(120000 * 0.05).toLocaleString()}円)をかけたもの</td>
										</tr>
										<tr>
											<td>DB使用数</td>
											<td className="text-right">{db.toLocaleString()}</td>
											<td className="text-right">{(64 * 24 * 5).toLocaleString()}</td>
											<td className="text-right">{(db - 64 * 24 * 5) > 0 ? (db - 64 * 24 * 5).toLocaleString() : 0}</td>
											<td className="text-right">{(db - 64 * 24 * 5) > 0 ? (Math.round((db - 64 * 24 * 5) / 720) * (10000)).toLocaleString() : 0}円</td>
											<td>「超過使用分」に720（月間使用時間）を割ったものに1万円税別をかけたもの</td>
										</tr>
									</table>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }}>
					<Col sm={12}>
						<Table keyField={'ID'} data={view} columns={columns} sort={ {field: 'TTL', order: 'desc'} } />
					</Col>
				</Row>


			</Container>
		);
	}
};

export default withRouter(Default);
