
const GraphQL = {
	get : 	`query get {
					get {
						items {
							ID
							ADD
							TTL
							EC2
							RDS
						}
						nextToken
					}
				}`,
	put :	`mutation put($data: DataInput!) {
				put(input: $data) {
					ID
				}
			}`,
	delete :	`mutation delete($ID: String!) {
				delete(ID: $ID) {
					ID
				}
			}`,
	getHistory : 	`query getHistory($start_date:Int!, $end_date:Int!, $limit: Int, $nextToken: String) {
					getHistory(start_date: $start_date, end_date: $end_date, limit: $limit, nextToken: $nextToken) {
						items {
							ID
							ADD
							TTL
							EC2
							RDS
						}
						nextToken
					}
				}`,
	getReserve : 	`query getReserve($limit: Int, $nextToken: String) {
					getReserve(limit: $limit, nextToken: $nextToken) {
						items {
							ID
							ADD
							TTL
							EC2
							RDS
							EXE
						}
						nextToken
					}
				}`,
	putReserve :	`mutation putReserve($data: ReserveInput!) {
					putReserve(input: $data) {
						ID
					}
				}`,
	deleteReserve :	`mutation deleteReserve($ID: String!) {
					deleteReserve(ID: $ID) {
						ID
					}
				}`,
	
}

export default GraphQL;
