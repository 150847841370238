import {
	Row,
	Col,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';


const FormText = (props) => {
		
	return (
		<Row>
			<Form.Label column sm={3}>
				{props.label} <Badge variant="danger">{props.required ? '必須' : ''}</Badge>
			</Form.Label>
			<Col sm={9}>
				<Form.Group controlId={props.name}>
				        <Form.Control name={props.name} type={props.type ? props.type : 'text'} value={props.value} defaultValue={props.defaultValue} size={props.size ? props.size : 'md'} required={props.required ? true : false} readOnly={props.readOnly ? true : false} disabled={props.disabled ? true : false} pattern={props.pattern} onChange={props.onChange} min={props.min} max={props.max} />
				</Form.Group>
			</Col>
		</Row>
	);
};

const FormTextArea = (props) => {
		
	return (
		<Row>
			<Form.Label column sm={3}>
				{props.label} <Badge variant="danger">{props.required ? '必須' : ''}</Badge>
			</Form.Label>
			<Col sm={9}>
				<Form.Group controlId={props.name}>
					<Form.Control as="textarea" name={props.name} value={props.value} defaultValue={props.defaultValue} size={props.size ? props.size : 'md'} required={props.required ? true : false} readOnly={props.readOnly ? true : false} disabled={props.disabled ? true : false} rows={props.rows} onChange={props.onChange} />
				</Form.Group>
			</Col>
		</Row>
	);
};

const FormSelect = (props) => {
		
	return (
		<Row>
			<Form.Label column sm={3}>
				{props.label} <Badge variant="danger">{props.required ? '必須' : ''}</Badge>
			</Form.Label>
			<Col sm={9}>
				<Form.Group controlId={props.name}>
					<Form.Control name={props.name} as="select" value={props.value} defaultValue={props.defaultValue} size={props.size ? props.size : 'md'} required={props.required ? true : false} readOnly={props.readOnly ? true : false} disabled={props.disabled ? true : false} onChange={props.onChange} >
						<option value="">----</option>
						{props.data.map((item, index) => <option key={index} value={item}>{item}</option>)}
					</Form.Control>
				</Form.Group>
			</Col>
		</Row>
	);
};

const FormButton = (props) => {
		
	return (
		<Row>
			<Form.Label column sm={3}>
				{props.label} <Badge variant="danger">{props.required ? '必須' : ''}</Badge>
			</Form.Label>
			<Col sm={9}>
				<Form.Group controlId={props.name}>
				        <Button name={props.name} variant={props.variant ? props.variant : 'light'} type="button" block onClick={props.onClick} size={props.size ? props.size : 'md'} required={props.required ? true : false} disabled={props.disabled ? true : false} className="text-left">
				        	{props.defaultValue ? props.defaultValue : props.value}
				        </Button>
				</Form.Group>
			</Col>
		</Row>
	);
};


export { FormText, FormTextArea, FormSelect, FormButton };

